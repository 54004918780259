<template>
  <pannel class="mt-4" title="DOORS" :onclickRight="onclickRight">
    <template #icon>
      <svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.3221 8.99291C10.3221 11.7988 10.3221 14.6051 10.3217 17.411C10.3217 17.5178 10.3263 17.6241 10.272 17.7255C10.1793 17.8982 10.0344 17.9825 9.84279 17.9983C9.82173 18 9.80067 17.9992 9.77962 17.9992C6.70198 17.9992 3.62434 17.9987 0.546705 18C0.344955 18 0.18406 17.9312 0.0758142 17.7601C0.0307469 17.6892 0.00084238 17.6112 0.00168476 17.5253C0.00210595 17.5011 0.00126357 17.4765 0.00126357 17.4523C0.00126357 11.8159 0.00126357 6.17952 0 0.543154C0 0.352925 0.0577031 0.196069 0.218177 0.0851024C0.291042 0.0350422 0.371069 0.000834338 0.462888 0.00250301C0.491108 0.00292018 0.518906 0.00125151 0.547126 0.00125151C3.62476 0.00125151 6.7024 0.00208584 9.78004 0C10.0151 0 10.1848 0.0867711 10.2863 0.298693C10.3255 0.380458 10.3213 0.467646 10.3213 0.554C10.3221 1.55062 10.3217 2.54723 10.3217 3.54385C10.3221 5.36062 10.3221 7.17656 10.3221 8.99291ZM6.8793 7.11815C6.8793 6.14239 6.87467 5.16622 6.88098 4.19046C6.88435 3.65231 6.44084 3.21512 5.89203 3.21554C5.24255 3.21595 4.5935 3.21637 3.94402 3.21512C3.81556 3.2147 3.69131 3.23681 3.57506 3.28562C3.15514 3.46292 2.9517 3.78664 2.95044 4.23259C2.94749 5.63929 2.94917 7.04598 2.94917 8.45267C2.94917 8.98039 2.94538 9.50853 2.95086 10.0362C2.95591 10.5623 3.38047 10.9924 3.90191 10.997C4.58255 11.0024 5.26361 10.9978 5.94468 10.9987C6.05924 10.9987 6.16917 10.9728 6.2711 10.9277C6.66744 10.7521 6.87846 10.4505 6.87888 10.0158C6.87972 9.04923 6.8793 8.0839 6.8793 7.11815Z"
          fill="#0072BB" />
      </svg>
    </template>

    <div class="mb-6 text-center">{{ train_number }}</div>
    <div id="md1_train" class="train_symbol">
      <div class="train_head"><span>MD1</span></div>
      <div class="train_cabin1">
        <div class="door d_1_2" :class="door_1_2_status_style"><div class="-mt-5 text-xs">2</div></div>
        <div class="door d_1_1" :class="door_1_1_status_style"><div class="mt-3 text-xs">1</div></div>
      </div>
      <div class="train_cabin2">
        <div class="door d_2_1" :class="door_2_1_status_style"><div class="-mt-5 text-xs">1</div></div>
        <div class="door d_2_2" :class="door_2_2_status_style"><div class="mt-3 text-xs">2</div></div>
      </div>
      <div class="train_tail">MD2</div>
    </div>

    <div class="mt-8 flex items-center justify-center">
      <div class="h-4 w-4 rounded-full bg-gray-600"></div>
      <span class="ml-2">Locked</span>
      <div class="ml-6 h-4 w-4 rounded-full bg-green-600"></div>
      <span class="ml-2">Open</span>
      <div class="ml-6 h-4 w-4 rounded-full bg-[#DA8076]"></div>
      <span class="ml-2">Faulty</span>
    </div>
  </pannel>
</template>
<script>
export default {
  inject: ["device_online", "device_boot_status"],
  props: {
    onclickRight: {
      type: Function,
      default: null,
    },

    train_number: {
      type: String,
      default: "-/-",
    },

    door_1_1_state: {
      type: Number,
      default: 0,
    },

    door_1_2_state: {
      type: Number,
      default: 0,
    },

    door_2_1_state: {
      type: Number,
      default: 0,
    },

    door_2_2_state: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    status_available() {
      return this.device_online && this.device_boot_status === 1;
    },

    door_1_1_status() {
      if (!this.status_available) {
        return "locked";
      }

      if ((this.door_1_1_state & 0b0001) > 0 && (this.door_1_1_state & 0b0010) === 0) {
        return "locked";
      }
      if ((this.door_1_1_state & 0b0001) === 0 && (this.door_1_1_state & 0b0010) > 0) {
        return "open";
      }
      if ((this.door_1_1_state & 0b0001) > 0 && (this.door_1_1_state & 0b0010) > 0) {
        return "Error";
      }
      if ((this.door_1_1_state & 0b0001) === 0 && (this.door_1_1_state & 0b0010) === 0) {
        return "Unknown";
      }
    },

    door_1_2_status() {
      if (!this.status_available) {
        return "locked";
      }

      if ((this.door_1_2_state & 0b0001) > 0 && (this.door_1_2_state & 0b0010) === 0) {
        return "locked";
      }
      if ((this.door_1_2_state & 0b0001) === 0 && (this.door_1_2_state & 0b0010) > 0) {
        return "open";
      }
      if ((this.door_1_2_state & 0b0001) > 0 && (this.door_1_2_state & 0b0010) > 0) {
        return "Error";
      }
      if ((this.door_1_2_state & 0b0001) === 0 && (this.door_1_2_state & 0b0010) === 0) {
        return "Unknown";
      }
    },

    door_2_1_status() {
      if (!this.status_available) {
        return "locked";
      }

      if ((this.door_2_1_state & 0b0001) > 0 && (this.door_2_1_state & 0b0010) === 0) {
        return "locked";
      }
      if ((this.door_2_1_state & 0b0001) === 0 && (this.door_2_1_state & 0b0010) > 0) {
        return "open";
      }
      if ((this.door_2_1_state & 0b0001) > 0 && (this.door_2_1_state & 0b0010) > 0) {
        return "Error";
      }
      if ((this.door_2_1_state & 0b0001) === 0 && (this.door_2_1_state & 0b0010) === 0) {
        return "Unknown";
      }
    },

    door_2_2_status() {
      if (!this.status_available) {
        return "locked";
      }

      if ((this.door_2_2_state & 0b0001) > 0 && (this.door_2_2_state & 0b0010) === 0) {
        return "locked";
      }
      if ((this.door_2_2_state & 0b0001) === 0 && (this.door_2_2_state & 0b0010) > 0) {
        return "open";
      }
      if ((this.door_2_2_state & 0b0001) > 0 && (this.door_2_2_state & 0b0010) > 0) {
        return "Error";
      }
      if ((this.door_2_2_state & 0b0001) === 0 && (this.door_2_2_state & 0b0010) === 0) {
        return "Unknown";
      }
    },

    door_1_1_status_style() {
      return {
        "bg-gray-600": this.door_1_1_status === "locked",
        "bg-green-600": this.door_1_1_status === "open",
        "bg-[#DA8076]": this.door_1_1_status === "Error" || this.door_1_1_status === "Unknown",
      };
    },
    door_1_2_status_style() {
      return {
        "bg-gray-600": this.door_1_2_status === "locked",
        "bg-green-600": this.door_1_2_status === "open",
        "bg-[#DA8076]": this.door_1_2_status === "Error" || this.door_1_2_status === "Unknown",
      };
    },
    door_2_1_status_style() {
      return {
        "bg-gray-600": this.door_2_1_status === "locked",
        "bg-green-600": this.door_2_1_status === "open",
        "bg-[#DA8076]": this.door_2_1_status === "Error" || this.door_2_1_status === "Unknown",
      };
    },
    door_2_2_status_style() {
      return {
        "bg-gray-600": this.door_2_2_status === "locked",
        "bg-green-600": this.door_2_2_status === "open",
        "bg-[#DA8076]": this.door_2_2_status === "Error" || this.door_2_2_status === "Unknown",
      };
    },
  },
};
</script>
