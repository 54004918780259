<template>
  <div class="flex">
    <div class="flex items-center">
      <div style="border: 3px solid #3f6ea9; width: 40px; height: 20px; border-radius: 2px" class="flex items-stretch">
        <div v-if="available" style="margin: 1px" :style="{ width: levelWidth + 'px', backgroundColor: levelColor }"></div>
        <div v-else style="margin: 1px" :style="{ width: '0px', backgroundColor: '#EEBB5A' }"></div>
      </div>
      <div style="margin-left: 2px; background-color: #3f6ea9; width: 3px; height: 8px"></div>
    </div>

    <svg v-if="available && (bms_charging_status === 0 || bms_charging_status === 2)" class="ml-1" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.24169 8.51652V9.01652H7.74169H11.0978L5.40285 18.1285V11.3554V10.8554H4.90285H1.54672L7.24169 1.7434V8.51652Z" stroke="#0072BB" />
    </svg>

    <svg v-if="available && bms_charging_status === 1" class="ml-1" width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 8.51652H7.74169V0L0.644592 11.3554H4.90285V19.8719L12 8.51652Z" fill="#0072BB" />
    </svg>
  </div>
</template>
<script>
export default {
  inject: ["device_online", "device_boot_status"],
  props: {
    available: {
      type: Boolean,
      default: false,
    },

    bms_charging_status: {
      type: Number,
    },

    bms_soc: {
      type: Number,
      required: true,
    },
  },

  computed: {
    levelWidth() {
      const value = (this.bms_soc / 1000) * 32;
      return value;
    },

    levelColor() {
      if (this.bms_soc <= 400) {
        return "#EEBB5A";
      } else {
        return "#3EA400";
      }
    },
  },
};
</script>
