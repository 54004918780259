<template>
  <pannel title="PASSENGERS" :onclickRight="onclickRight">
    <template #icon>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 8.1C10.1935 8.1 11.3381 8.57411 12.182 9.41802C13.0259 10.2619 13.5 11.4065 13.5 12.6V18H4.5V12.6C4.5 11.4065 4.97411 10.2619 5.81802 9.41802C6.66193 8.57411 7.80653 8.1 9 8.1ZM2.9592 10.8054C2.81597 11.2885 2.73136 11.7871 2.7072 12.2904L2.7 12.6V18H8.21852e-08V13.95C-0.000177126 13.1738 0.286225 12.4249 0.804271 11.8469C1.32232 11.2689 2.03553 10.9025 2.8071 10.818L2.9601 10.8054H2.9592ZM15.0408 10.8054C15.8417 10.8542 16.5938 11.2068 17.1437 11.7911C17.6936 12.3755 17.9999 13.1476 18 13.95V18H15.3V12.6C15.3 11.9763 15.21 11.3742 15.0408 10.8054ZM3.15 5.4C3.74674 5.4 4.31903 5.63705 4.74099 6.05901C5.16295 6.48097 5.4 7.05326 5.4 7.65C5.4 8.24674 5.16295 8.81903 4.74099 9.24099C4.31903 9.66295 3.74674 9.9 3.15 9.9C2.55326 9.9 1.98097 9.66295 1.55901 9.24099C1.13705 8.81903 0.9 8.24674 0.9 7.65C0.9 7.05326 1.13705 6.48097 1.55901 6.05901C1.98097 5.63705 2.55326 5.4 3.15 5.4ZM14.85 5.4C15.4467 5.4 16.019 5.63705 16.441 6.05901C16.8629 6.48097 17.1 7.05326 17.1 7.65C17.1 8.24674 16.8629 8.81903 16.441 9.24099C16.019 9.66295 15.4467 9.9 14.85 9.9C14.2533 9.9 13.681 9.66295 13.259 9.24099C12.8371 8.81903 12.6 8.24674 12.6 7.65C12.6 7.05326 12.8371 6.48097 13.259 6.05901C13.681 5.63705 14.2533 5.4 14.85 5.4ZM9 0C9.95478 0 10.8705 0.379285 11.5456 1.05442C12.2207 1.72955 12.6 2.64522 12.6 3.6C12.6 4.55478 12.2207 5.47045 11.5456 6.14558C10.8705 6.82072 9.95478 7.2 9 7.2C8.04522 7.2 7.12955 6.82072 6.45442 6.14558C5.77928 5.47045 5.4 4.55478 5.4 3.6C5.4 2.64522 5.77928 1.72955 6.45442 1.05442C7.12955 0.379285 8.04522 0 9 0Z"
          fill="#0072BB" />
      </svg>
    </template>

    <div class="mt-4 grid grid-cols-3 place-items-stretch content-center" style="grid-template-columns: 68px 1fr">
      <div v-if="device_online && device_boot_status === 1" class="mr-4 mt-1 place-self-end">
        <span class="text-3xl font-bold">{{ capacity }}</span
        >%
      </div>
      <div v-else class="mr-4 mt-1 place-self-end">
        <span class="text-3xl font-bold text-second-black">-/-</span>
      </div>

      <div class="mt-1">
        <passengers :capacity="capacity" />
      </div>
      <div class="mr-4 mt-2 place-self-end text-sm" :class="{ 'text-second-black': !device_online || device_boot_status !== 1 }">Capacity</div>
      <div v-if="device_online && device_boot_status === 1" class="mt-2 text-sm">
        <span class="font-bold">{{ passenger_count }}</span> Passengers on Board
      </div>
      <div v-else class="mt-2 text-sm text-second-black"><span class="font-bold">-/-</span> Passengers on Board</div>
    </div>
  </pannel>
</template>
<script>
export default {
  inject: ["device_online", "device_boot_status"],
  props: {
    onclickRight: {
      type: Function,
      default: null,
    },

    passenger_count: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    capacity() {
      if (!this.device_online || this.device_boot_status !== 1) {
        return 0;
      }
      const total_capacity = 72.0;
      const c_i = Math.floor((this.passenger_count / total_capacity) * 100);
      return c_i;
    },
  },
};
</script>
