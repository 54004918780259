<template>
  <li :class="{ online: device.is_online, offline: !device.is_online }" @click="onSelected(device)" class="flex cursor-pointer justify-between space-x-4 border-b border-t px-4 py-2 hover:bg-gray-100">
    <!--<div class="mt-1 flex flex-col items-start"><img src="@/assets/images/pin_icon.svg" class="h-6 w-6 opacity-0" /></div>-->
    <div class="mt-1 flex flex-col items-start">
      <svg width="9" height="11" viewBox="0 0 9 11" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="device.is_online && device.boot_status === 1 && device.train_contacted">
        <rect x="0.5" y="0.5" width="6" height="8" stroke="#555555" />
        <rect x="2.5" y="2.5" width="6" height="8" stroke="#555555" />
      </svg>
    </div>

    <div class="flex grow flex-col space-y-2">
      <h1 class="flex items-center text-base">
        <svg v-if="!device.is_online || device.boot_status !== 1" width="14" height="16" class="mr-2" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0444 14.3158L12.4444 15.5789V16H1.55556V15.5789L2.95556 14.3158H1.55556C1.143 14.3158 0.747335 14.1383 0.455612 13.8225C0.163888 13.5066 0 13.0783 0 12.6316V3.36842C0 2.47506 0.327777 1.61829 0.911223 0.986588C1.49467 0.354886 2.28599 0 3.11111 0H10.8889C11.714 0 12.5053 0.354886 13.0888 0.986588C13.6722 1.61829 14 2.47506 14 3.36842V12.6316C14 13.0783 13.8361 13.5066 13.5444 13.8225C13.2527 14.1383 12.857 14.3158 12.4444 14.3158H11.0444ZM1.55556 3.36842V6.73684H12.4444V3.36842H1.55556ZM7 12.6316C7.41256 12.6316 7.80822 12.4541 8.09994 12.1383C8.39167 11.8224 8.55556 11.394 8.55556 10.9474C8.55556 10.5007 8.39167 10.0723 8.09994 9.75645C7.80822 9.4406 7.41256 9.26316 7 9.26316C6.58744 9.26316 6.19178 9.4406 5.90006 9.75645C5.60833 10.0723 5.44444 10.5007 5.44444 10.9474C5.44444 11.394 5.60833 11.8224 5.90006 12.1383C6.19178 12.4541 6.58744 12.6316 7 12.6316Z"
            fill="#555555"
            fill-opacity="0.5" />
        </svg>
        <svg v-if="device.is_online && device.boot_status === 1" width="14" height="16" class="mr-2" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M11.0444 14.3158L12.4444 15.5789V16H1.55556V15.5789L2.95556 14.3158H1.55556C1.143 14.3158 0.747335 14.1383 0.455612 13.8225C0.163888 13.5066 0 13.0783 0 12.6316V3.36842C0 2.47506 0.327777 1.61829 0.911223 0.986588C1.49467 0.354886 2.28599 0 3.11111 0H10.8889C11.714 0 12.5053 0.354886 13.0888 0.986588C13.6722 1.61829 14 2.47506 14 3.36842V12.6316C14 13.0783 13.8361 13.5066 13.5444 13.8225C13.2527 14.1383 12.857 14.3158 12.4444 14.3158H11.0444ZM1.55556 3.36842V6.73684H12.4444V3.36842H1.55556ZM7 12.6316C7.41256 12.6316 7.80822 12.4541 8.09994 12.1383C8.39167 11.8224 8.55556 11.394 8.55556 10.9474C8.55556 10.5007 8.39167 10.0723 8.09994 9.75645C7.80822 9.4406 7.41256 9.26316 7 9.26316C6.58744 9.26316 6.19178 9.4406 5.90006 9.75645C5.60833 10.0723 5.44444 10.5007 5.44444 10.9474C5.44444 11.394 5.60833 11.8224 5.90006 12.1383C6.19178 12.4541 6.58744 12.6316 7 12.6316Z"
            fill="#3EA400" />
        </svg>

        <span class="font-bold" :class="{ 'text-[#3EA400]': device.is_online, 'text-[#AAAAAA]': !device.is_online || device.boot_status !== 1 }">{{ device.trainno }}</span>

        <span v-if="!device.is_online" class="ml-2 font-normal text-[#555555]">(Offline)</span>
        <span v-else-if="device.boot_status === 0" class="ml-2 font-normal text-[#555555]">(Power Off)</span>
        <span v-else-if="device.boot_status === 2" class="ml-2 font-normal text-[#555555]">(Booting up)</span>
        <div v-else>
          <span class="mx-2 text-primary-black">to</span>
          <span class="font-bold text-primary-black">{{ device.end_station }}</span>
        </div>
      </h1>
      <div class="flex text-sm text-[#555555]">
        Speed:
        <div v-if="device.is_online && device.boot_status === 1">
          <span class="ml-3 font-bold text-primary-black">{{ (device.train_speed / 100).toFixed(0) }}</span> km/h
        </div>
      </div>
      <div v-if="user_role === 'controller' || user_role === 'monitor'" class="flex text-sm text-[#555555]">
        Battery:
        <div v-if="device.is_online && device.boot_status === 1">
          <span class="ml-1 font-bold text-primary-black">{{ ((device.md1_soc + device.md2_soc) / 2.0 / 10.0).toFixed(0) }}%</span>
        </div>
      </div>
      <div v-if="user_role === 'passenger'" class="flex text-sm text-[#555555]">
        Next Stop:
        <div v-if="device.is_online && device.boot_status === 1">
          <span class="ml-1 font-bold text-primary-black">{{ device.next_station }}</span>
        </div>
      </div>
    </div>
    <img src="@/assets/images/right_arrow_icon.svg" class="mt-7 h-6 w-6 opacity-60" />
  </li>
</template>
<script>
export default {
  props: {
    device: {
      type: Object,
      default: null,
    },

    user_role: {
      type: String,
      default: null,
    },

    onSelected: {
      type: Function,
      default: null,
    },
  },
};
</script>
