const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export default function formatDate(date_string) {
  let date_o = new Date(date_string); // "2023-03-17T03:26:59.530+08:00"
  const year = date_o.getFullYear(); // 2019
  let date = date_o.getDate(); // 23
  let month = date_o.getMonth() + 1;
  if (date < 10) {
    date = `0${date}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  const dayIndex = date_o.getDay();
  const dayName = days[dayIndex]; // Thu
  const formatted = `${dayName} ${date}/${month}/${year}`;
  return formatted;
}
