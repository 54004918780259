import { defineStore } from "pinia";

import authHeader from "@/libs/auth_header";

export const DeviceStore = defineStore("devices", {
  state: () => {
    return {
      device: {
        fault_code: [],
      },
      user: {},
      devices: [],
    };
  },

  getters: {},

  actions: {
    async index() {
      return this.axios.get("/devices", { headers: authHeader() }).then((response) => {
        this.devices = response.data.devices;
        this.user = response.data.user;
      });
    },

    async show(id) {
      return this.axios.get(`/devices/${id}`, { headers: authHeader() }).then((response) => {
        this.device = response.data.device;
        this.user = response.data.user;
      });
    },

    update_boot_status(is_up) {
      this.device.boot_status = is_up;
    },

    sync_device(info) {
      // TODO: Remove log
      // console.log("sync_device Debug:", Object.keys(info), info);
      if (this.devices.length === 0) {
        return;
      }
      let properties = Object.keys(info);
      let upd_obj_i = this.devices.findIndex((obj) => obj.device_serial === info.device_serial);
      if (upd_obj_i == -1) {
        return;
      }
      for (let i = 0; i < properties.length; i++) {
        let p = properties[i];
        if (p === "action" || p === "device_serial") {
          continue;
        }
        this.devices[upd_obj_i][p] = info[p];
      }
    },

    sync_current_device(info) {
      let properties = Object.keys(info);
      if (this.device.device_serial !== info.device_serial) {
        console.log("Ignore sync device");
        return;
      }

      for (let i = 0; i < properties.length; i++) {
        let p = properties[i];
        if (p === "action" || p === "device_serial") {
          continue;
        }
        this.device[p] = info[p];
      }
    },
  },
});
