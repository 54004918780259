import { defineStore } from "pinia";
import authHeader from "@/libs/auth_header";
import formatDate from "@/libs/format_date";

export const SystemStore = defineStore("system", {
  state: () => {
    return {
      server_date: "",
      app_title: "",
    };
  },

  actions: {
    async info() {
      return this.axios.get("/system/info", { headers: authHeader() }).then((response) => {
        this.server_date = formatDate(response.data.time);
      });
    },

    async set_title(tit) {
      if (window.flutterEvent) {
        window.flutterEvent.postMessage("titleChanged:" + tit);
      }
      this.app_title = tit;
    },
  },
});
