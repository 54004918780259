// settings.js

// 默认状态为关闭
let debugCableEnabled = false;
let debug_received_log = false;
window.debug_received_log = debug_received_log;

// 检查本地存储中是否存在调试状态
const storedDebugValue = localStorage.getItem("debugCableEnabled");
if (storedDebugValue) {
  // debugCableEnabled = JSON.parse(storedDebugValue);
  debugCableEnabled = storedDebugValue === "true";
}

// 导出开启调试的方法
export function enable_cable_debug(enabled) {
  debugCableEnabled = enabled;

  // 将调试状态保存到本地存储中
  // localStorage.setItem("debugCableEnabled", JSON.stringify(enabled));
  localStorage.setItem("debugCableEnabled", enabled.toString());
}

// 导出是否开启调试的函数
export function isCableDebugEnabled() {
  return debugCableEnabled;
}

window.enable_cable_debug = enable_cable_debug;
