<template>
  <div>
    <div class="px-4 pb-2 pt-4">
      <div class="flex items-center justify-between rounded-xl border bg-gray-100 px-4 py-2 font-bold">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
        </svg>

        <input type="text" name="train" class="ml-4 grow border-0 bg-gray-100 text-base" />
      </div>
    </div>

    <ul class="trains">
      <DeviceItem v-for="(device, index) in store.devices" :key="device.id" :device="device" :user_role="store.user.role" :onSelected="selectDevice"></DeviceItem>
    </ul>
  </div>
</template>

<script>
import { DeviceStore } from "@/stores/device_store";
import { SystemStore } from "@/stores/system_store";
import { createUserDevicesChannel } from "@/channels/user_devices_channel";

export default {
  setup() {
    console.log("Debug: devices.vue setup() callback");
    const store = DeviceStore();
    const system_store = SystemStore();

    return { store, system_store, user_devices_channel: null };
  },
  methods: {
    selectDevice(device) {
      this.$router.push({ name: "device_path", params: { device_serial: device.device_serial } });
    },

    on_devicesChanged() {
      this.$api.call(this.store.index()).then((response) => {});
    },

    on_device_online_changed(data) {
      console.log("==== on_device_online_changed ....", data);
      this.store.sync_device(data);
    },

    on_deviceHeartbeat(data) {
      this.store.sync_device(data);
    },

    on_bootStatusChanged(data) {
      console.log("WHen device status changed....", data);
      this.store.sync_device(data);
    },

    on_properties_changed(data) {
      console.log("WHen device properties changed....", data);
      this.store.sync_device(data);
    },
  },

  created() {
    console.log("---- devices.vue page created ----");
    this.$api.call(this.store.index()).then((response) => {});

    this.timer = setInterval(() => {
      this.$api.call(this.store.index()).then((response) => {});
    }, 4000);
  },

  destroyed() {
    console.log("---- devices.vue page destory ----");
  },

  mounted() {
    console.log("---- devices.vue page mounted ----");
    this.system_store.set_title("Select a Train");
    this.user_devices_channel = createUserDevicesChannel();
    this.user_devices_channel.on("devices_changed", this.on_devicesChanged);
    this.user_devices_channel.on("online_status_changed", this.on_device_online_changed);
    this.user_devices_channel.on("device_heartbeat", this.on_deviceHeartbeat);
    this.user_devices_channel.on("boot_status_changed", this.on_bootStatusChanged);
    this.user_devices_channel.on("properties_changed", this.on_properties_changed);

    let selected_device = null;
    // check store device is not null
    if (this.store.device) {
      selected_device = Object.keys(this.store.device).length > 1;
    }

    if (selected_device) {
      this.$router.push({ name: "device_path", params: { device_serial: this.store.device.device_serial } });
    }
  },

  unmounted() {
    console.log("---- devices.vue page unmounted ----");
    clearInterval(this.timer);
    this.user_devices_channel.cancleOn("devices_changed", this.on_devicesChanged);
    this.user_devices_channel.cancleOn("online_status_changed", this.on_device_online_changed);
    this.user_devices_channel.cancleOn("device_heartbeat", this.on_deviceHeartbeat);
    this.user_devices_channel.cancleOn("boot_status_changed", this.on_bootStatusChanged);
    this.user_devices_channel.cancleOn("properties_changed", this.on_properties_changed);
    this.user_devices_channel.unsubscribeChannel();
  },
};
</script>
