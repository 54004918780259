<template>
  <pannel class="mt-4" title="POWER" :onclickRight="onclickRight">
    <template #icon>
      <svg width="8" height="19" viewBox="0 0 8 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.92187 14.6688H7.42564L3.79077 19C3.07319 17.5493 2.38397 16.1545 1.66191 14.6952H3.36111C3.74452 13.2025 4.11599 11.7541 4.51655 10.1955L0 11.4381C0.690723 7.58602 1.36802 3.80699 2.05054 0H6.02107L3.47972 7.8159L8 6.56244C7.2966 9.30623 6.61706 11.9568 5.92187 14.6688Z"
          fill="#0072BB" />
      </svg>
    </template>

    <div class="flex justify-between">
      <div class="flex w-1/2 flex-col items-center">
        <BatteryStatusWidget :available="status_available" :bms_charging_status="md1_bms_charging_status" :bms_soc="md1_soc" class="mt-4"></BatteryStatusWidget>
        <div class="mt-2 text-3xl font-bold text-primary-black" v-if="status_available">{{ (md1_soc / 10).toFixed(0) }}<span class="text-lg">%</span></div>
        <div class="mt-2 text-3xl font-bold text-second-black" v-else>-<span class="text-lg">%</span></div>
        <div class="mt-2 text-sm text-primary-black" v-if="status_available">{{ (md1_power_battery_v / 10).toFixed(1) }} v</div>
        <div class="mt-2 text-sm text-second-black" v-else>- v</div>
        <div class="mt-4 flex w-full flex-row items-center justify-between text-sm" v-if="status_available">
          <div class="w-1/2 text-center font-bold" :class="{ 'text-second-black': !md1_high_voltage, 'text-green-600': md1_high_voltage }">
            High<br />
            Voltage
          </div>
          <div class="w-1/2 text-center font-bold" :class="{ 'text-second-black': !md1_380_voltage, 'text-green-600': md1_380_voltage }">{{ md1_380_ouput_v }} v</div>
        </div>
      </div>
      <div class="flex w-1/2 flex-col items-center">
        <BatteryStatusWidget :available="status_available" :bms_charging_status="md2_bms_charging_status" :bms_soc="md2_soc" class="mt-4"></BatteryStatusWidget>
        <div class="mt-2 text-3xl font-bold text-primary-black" v-if="status_available">{{ (md2_soc / 10).toFixed(0) }}<span class="text-lg">%</span></div>
        <div class="mt-2 text-3xl font-bold text-second-black" v-else>-<span class="text-lg">%</span></div>
        <div class="mt-2 text-sm text-primary-black" v-if="status_available">{{ (md2_power_battery_v / 10).toFixed(1) }} v</div>
        <div class="mt-2 text-sm text-second-black" v-else>- v</div>
        <div class="mt-4 flex w-full flex-row items-center justify-between text-sm" v-if="status_available">
          <div class="w-1/2 text-center font-bold" :class="{ 'text-second-black': !md2_high_voltage, 'text-green-600': md2_high_voltage }">
            High<br />
            Voltage
          </div>
          <div class="w-1/2 text-center font-bold" :class="{ 'text-second-black': !md2_380_voltage, 'text-green-600': md2_380_voltage }">{{ md2_380_ouput_v }} v</div>
        </div>
      </div>
    </div>
  </pannel>
</template>
<script>
export default {
  inject: ["device_online", "device_boot_status"],
  props: {
    onclickRight: {
      type: Function,
      default: null,
    },
    md1_soc: {
      type: Number,
      default: 0,
    },
    md2_soc: {
      type: Number,
      default: 0,
    },
    md1_power_battery_v: {
      type: Number,
    },
    md2_power_battery_v: {
      type: Number,
    },
    md1_bms_charging_status: {
      type: Number,
      default: 0,
    },
    md2_bms_charging_status: {
      type: Number,
      default: 0,
    },

    md1_high_voltage: {
      type: Boolean,
      default: false,
    },
    md2_high_voltage: {
      type: Boolean,
      default: false,
    },

    md1_380_voltage: {
      type: Boolean,
      default: false,
    },
    md2_380_voltage: {
      type: Boolean,
      default: false,
    },

    md1_380_ouput_v: {
      type: Number,
      default: 0,
    },
    md2_380_ouput_v: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    status_available() {
      return this.device_online && this.device_boot_status === 1;
    },
    // md1_bms_charging_status_name() {
    //   if(this.md1_bms_charging_status === 0) {
    //     return "Not Charging";
    //   } else if(this.md1_bms_charging_status === 1) {
    //     return "Charging";
    //   } else if(this.md1_bms_charging_status === 2) {
    //     return "Charged";
    //   } else if(this.md1_bms_charging_status === 3) {
    //     return "Error";
    //   }
    // },

    // md2_bms_charging_status_name() {
    //   if(this.md2_bms_charging_status === 0) {
    //     return "Not Charging";
    //   } else if(this.md2_bms_charging_status === 1) {
    //     return "Charging";
    //   } else if(this.md2_bms_charging_status === 2) {
    //     return "Charged";
    //   } else if(this.md2_bms_charging_status === 3) {
    //     return "Error";
    //   }
    // },
  },
};
</script>
