var fault_code_map = {
  1018: "车门打开",
  1019: "速度超过66km/h",
  1020: "Liveman被触发",
  1021: "总风压力低于550kPa时，紧急制动",
  1091: "它编组:车门打开",
  1092: "它编组:速度超过66km/h",
  1093: "它编组:Liveman被触发",
  1094: "它编组:总风压力低于550kPa时，紧急制动",
  2006: "BMS1:单体电压欠压严重报警",
  2008: "BMS1:电芯温度过高中等报警",
  2009: "BMS1:电芯温度过高严重报警",
  2018: "BMS1:脉冲充电电流超限中等报警",
  2019: "BMS1:脉冲充电电流超限严重报警",
  2021: "BMS1:电池包高温报警",
  2022: "BMS1:BMS1:电池包自保护故障严重报警",
  2027: "BMS1:BMU通信故障严重报警",
  2028: "BMS1:电流传感器故障",
  2029: "BMS1:BMU内部通信故障",
  2033: "BMS1:总压过压严重报警",
  2035: "BMS1:总压欠压中等报警",
  2036: "BMS1:总压欠压严重报警",
  2039: "BMS1:系统绝缘故障严重报警",
  2040: "BMS1:电芯过放故障严重报警",
  2041: "BMS1:接触器KM1无法闭合故障报警",
  2042: "BMS1:接触器KM1无法断开故障报警",
  2043: "BMS1:接触器KM2无法闭合故障报警",
  2044: "BMS1:接触器KM2无法断开故障报警",
  2048: "BMS1:消防系统火情严重报警",
  2066: "BMS1:充电接触器KM34无法闭合故障报警",
  2067: "BMS1:充电接触器KM34无法断开故障报警",
  2073: "BMS2:单体电压欠压严重报警",
  2075: "BMS2:电芯温度过高中等报警",
  2076: "BMS2:电芯温度过高严重报警",
  2085: "BMS2:脉冲充电电流超限中等报警",
  2086: "BMS2:脉冲充电电流超限严重报警",
  2088: "BMS2:电池包高温报警",
  2089: "BMS2:电池包自保护故障严重报警",
  2094: "BMS2:BMU通信故障严重报警",
  2095: "BMS2:电流传感器故障",
  2096: "BMS2:BMU内部通信故障",
  2100: "BMS2:总压过压严重报警",
  2102: "BMS2:总压欠压中等报警",
  2103: "BMS2:总压欠压严重报警",
  2106: "BMS2:系统绝缘故障严重报警",
  2107: "BMS2:电芯过放故障严重报警",
  2108: "BMS2:接触器KM1无法闭合故障报警",
  2109: "BMS2:接触器KM1无法断开故障报警",
  2110: "BMS2:接触器KM2无法闭合故障报警",
  2111: "BMS2:接触器KM2无法断开故障报警",
  2115: "BMS2:消防系统火情严重报警",
  2133: "BMS2:充电接触器KM34无法闭合故障报警",
  2134: "BMS2:充电接触器KM34无法断开故障报警",
  3015: "MD1车架空气制动不缓解故障",
  3018: "MD1车架紧急制动意外施加",
  3028: "MD1车停放制动不缓解",
  3030: "铰接架空气制动不缓解故障",
  3033: "铰接架紧急制动意外施加",
  3043: "MD2车停放制动不缓解",
  3045: "MD2车架空气制动不缓解故障",
  3048: "MD2车架紧急制动意外施加",
  7001: "MD1车:内部生命信号故障",
  7013: "MD1车:模块超温故障",
  7014: "MD1车:DCDC管故障",
  7015: "MD1车:斩波管故障",
  7016: "MD1车:逆变器管故障",
  7017: "MD1车:短接接触器故障",
  7018: "MD1车:充电接触器故障",
  7019: "MD1车:逆变器输出接触器故障",
  7027: "MD1车:辅变严重故障",
  7033: "MD1车:充电机管故障",
  7037: "MD1车:充电机严重故障",
  7038: "MD2车:内部生命信号故障",
  7050: "MD2车:模块超温故障",
  7051: "MD2车:DCDC管故障",
  7052: "MD2车:斩波管故障",
  7053: "MD2车:逆变器管故障",
  7054: "MD2车:短接接触器故障",
  7055: "MD2车:充电接触器故障",
  7056: "MD2车:逆变器输出接触器故障",
  7064: "MD2车:辅变严重故障",
  7070: "MD2车:充电机管故障",
  7074: "MD2车:充电机严重故障",
  8048: "MD1车:VVVF严重故障",
  8103: "MD2车:VVVF严重故障",
};

export { fault_code_map };
