<template>
  <pannel title="STATUS" :show_right_arrow="device_online && device_boot_status === 1" :onclickRight="onclickRight" class="flex flex-col">
    <template #icon>
      <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15 6.3V17.0937C15.0007 17.2119 14.98 17.3291 14.9388 17.4386C14.8976 17.5481 14.8369 17.6478 14.76 17.7319C14.6832 17.8161 14.5918 17.8831 14.4909 17.9291C14.3901 17.9751 14.2819 17.9992 14.1725 18H0.827499C0.608177 18 0.397825 17.906 0.242663 17.7386C0.0875011 17.5712 0.000220868 17.3441 0 17.1072V0.8928C0 0.4095 0.372499 0 0.831665 0H9.16665V5.4C9.16665 5.63869 9.25445 5.86761 9.41073 6.0364C9.56701 6.20518 9.77897 6.3 9.99999 6.3H15ZM15 4.5H10.8333V0.00270002L15 4.5Z"
          fill="#0072BB" />
      </svg>
    </template>

    <div class="my-4 flex grow flex-col items-center justify-center">
      <div v-if="!device_online" class="mt-2 text-sm text-second-black">Device Offline</div>
      <div v-if="device_online && device_boot_status === 0" class="mt-2 text-sm text-second-black">Device Power Off</div>
      <div v-if="device_online && device_boot_status === 2" class="mt-2 text-sm text-second-black">Device Booting up</div>

      <svg v-if="device_online && device_boot_status === 1 && fault_code.length === 0" width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.8333 41.6667C9.32708 41.6667 0 32.3396 0 20.8333C0 9.32708 9.32708 0 20.8333 0C32.3396 0 41.6667 9.32708 41.6667 20.8333C41.6667 32.3396 32.3396 41.6667 20.8333 41.6667ZM17.7563 30.1667L32.4854 15.4354L29.5396 12.4896L17.7563 24.275L11.8625 18.3813L8.91667 21.3271L17.7563 30.1667Z"
          fill="#3EA400" />
      </svg>

      <svg v-if="device_online && device_boot_status === 1 && fault_code.length > 0" width="42" height="42" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="8" fill="#DA8076" />
        <circle cx="8.00002" cy="12.2476" r="1.08571" fill="white" />
        <rect x="6.91431" y="3.19995" width="2.17143" height="6.51429" rx="1.08571" fill="white" />
      </svg>

      <div v-if="device_online && device_boot_status === 1 && fault_code.length === 0" class="mt-2 text-sm font-bold text-green-600">No Fault</div>
      <div v-if="device_online && device_boot_status === 1 && fault_code.length > 0" class="mt-2 text-sm font-bold text-[#DA8076]">Some Fault</div>
    </div>
  </pannel>
</template>

<script>
export default {
  inject: ["device_online", "device_boot_status"],
  props: {
    onclickRight: {
      type: Function,
      default: null,
    },

    show_right_arrow: {
      type: Boolean,
      default: false,
    },

    fault_code: {
      type: Array,
      default: [],
    },
  },
};
</script>
