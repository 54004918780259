<template>
  <div class="modal-details">
    <div class="overlay"></div>
    <div class="modal animate-modalslideshow rounded">
      <div class="mb-2 flex justify-between border-b px-4 py-3">
        <h3 class="font-medium leading-6 text-gray-900">{{ title }}</h3>
        <button @click="$emit('modal_closed')" class="outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="p-4">
        <slot></slot>
      </div>
      <div class="flex justify-end border-t px-4 py-3">
        <button type="button" class="btn btn-primary outline-none" tabindex="0" @click="$emit('modal_closed')">Got it, thanks!</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
