<template>
  <pannel title="DRIVING" :onclickRight="onclickRight">
    <template #icon>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9 0C13.9707 0 18 4.0293 18 9C18 13.9707 13.9707 18 9 18C4.0293 18 0 13.9707 0 9C0 4.0293 4.0293 0 9 0ZM9 2.7C5.5206 2.7 2.7 5.5206 2.7 9C2.7 10.6668 3.348 12.1833 4.4046 13.3101L4.545 13.4541L5.8176 12.1824C5.004 11.367 4.5 10.242 4.5 9C4.5 6.5151 6.5151 4.5 9 4.5C9.4032 4.5 9.7938 4.5531 10.1655 4.653L11.5722 3.2472C10.7865 2.8962 9.9162 2.7 9 2.7ZM14.7528 6.4287L13.3479 7.8345C13.4469 8.2062 13.5 8.5968 13.5 9C13.5 10.242 12.996 11.367 12.1824 12.1824L13.455 13.455C14.5944 12.3147 15.3 10.7397 15.3 9C15.3 8.0838 15.1047 7.2135 14.7528 6.4287ZM12.8178 3.9087L9.4653 7.2603C9.3168 7.2207 9.1611 7.2 9 7.2C8.0055 7.2 7.2 8.0055 7.2 9C7.2 9.9945 8.0055 10.8 9 10.8C9.9945 10.8 10.8 9.9945 10.8 9C10.8 8.8389 10.7793 8.6832 10.7397 8.5347L14.0913 5.1813L12.8178 3.9087Z"
          fill="#0072BB" />
      </svg>
    </template>

    <div class="flex items-center justify-between space-x-4">
      <div style="width: 160px">
        <svg viewBox="0 0 100 60" style="width: 160px; height: 90px">
          <path fill="none" stroke="rgb(210,233,233)" stroke-width="4" d="M5,55 A20,20 0 1,1 95,55" />
          <path fill="none" stroke="#0072BB" stroke-width="4" :stroke-dasharray="speed_progress" d="M5,55 A20,20 0 0,1 95,55" />
        </svg>
        <div class="-mt-12 h-10 text-center font-bold text-primary-black">
          <span v-if="!status_available" class="text-second-black">-/-</span>
          <div v-if="status_available">
            <span class="text-3xl">{{ speed_i }}</span
            >.<span>{{ speed_d }}</span>
          </div>
        </div>
        <div class="flex items-center justify-between" :class="{ 'text-second-black': !status_available }">
          <div class="ml-2 text-[8px]">0</div>
          <div class="text-sm">km/h</div>
          <div class="mr-2 text-[8px]">60</div>
        </div>

        <div class="mt-4 flex flex-col">
          <div class="flex items-end justify-between font-bold">
            <div class="text-sm" :class="{ 'text-primary-black': status_available, 'text-second-black': !status_available }">Brake</div>
            <div class="-mb-1" :class="{ 'text-green-600': status_available, 'text-second-black': !status_available }">
              <span class="text-3xl">{{ drive_brake_level }}</span
              ><span class="text-sm">%</span>
            </div>
            <div class="text-sm" :class="{ 'text-primary-black': status_available, 'text-second-black': !status_available }">Drive</div>
          </div>
          <H2wayIndicator :direction="indicator_direction" :levelValue="drive_brake_level"></H2wayIndicator>
          <div class="flex items-end justify-between text-[8px] font-semibold text-[#aaaaaa]">
            <div class="">100</div>
            <div class="">0</div>
            <div class="">100</div>
          </div>
        </div>
      </div>

      <div class="flex flex-col items-center">
        <ul class="text-primary-black" :class="{ 'text-second-black': !status_available, 'text-primary-black': status_available }">
          <li class="text-center">
            <div class="font-bold">
              <span v-if="!status_available" class="text-xl">-</span>
              <span v-if="status_available" class="text-xl">{{ odom }}</span> km
            </div>
            <div class="text-sm">Cumulative Mileage</div>
          </li>
          <li class="mt-3 text-center">
            <div class="font-bold">
              <span v-if="!status_available" class="text-xl">-</span>
              <span v-if="status_available" class="text-xl">{{ power_used }}</span> kWh
            </div>
            <div class="text-sm">Cumulative Power</div>
          </li>
          <li class="mt-3 text-center">
            <div class="font-bold">
              <span v-if="!status_available" class="text-xl">-</span>
              <span v-if="status_available" class="text-xl">{{ driving_range }}</span> km
            </div>
            <div class="text-sm">Driving Range</div>
          </li>
        </ul>
      </div>
    </div>
  </pannel>
</template>

<script>
export default {
  inject: ["device_online", "device_boot_status"],
  props: {
    onclickRight: {
      type: Function,
      default: null,
    },

    drive_applied: {
      type: Boolean,
      default: false,
    },

    brake_applied: {
      type: Boolean,
      default: false,
    },

    speed: {
      type: Number,
      default: 0,
    },

    soc: {
      type: Number,
      default: 0,
    },

    odom: {
      type: Number,
      default: 0,
    },

    power_used: {
      type: Number,
      default: 0,
    },

    driving_range: {
      type: Number,
      default: 0,
    },

    drive_brake_level: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    status_available() {
      return this.device_online && this.device_boot_status === 1;
    },

    indicator_direction() {
      if (this.brake_applied && !this.drive_applied) {
        return -1;
      } else if (!this.brake_applied && this.drive_applied) {
        return 1;
      } else if (this.brake_applied && this.drive_applied) {
        return 2;
      } else {
        return 0;
      }
    },
    speed_i() {
      const s_i = Math.floor(this.speed / 100);
      return s_i;
    },

    speed_d() {
      return this.speed % 100;
    },

    speed_progress() {
      if (!this.status_available) {
        return "0,2000";
      }
      return `${(((this.speed / 100) * 142) / 60).toFixed(0)},2000`;
    },
  },
};
</script>
