<template>
  <div class="md:1/3 mx-auto w-96 sm:w-2/3 lg:w-96">
    <div class="mt-4 px-4 sm:p-8">
      <div class="flex justify-center py-4 text-center">
        <img v-if="$route.params.role === 'controller'" src="@/assets/images/controller_role_icon2.png" />
        <img v-if="$route.params.role === 'monitor'" src="@/assets/images/monitor_role_icon2.png" />
        <img v-if="$route.params.role === 'passenger'" src="@/assets/images/passenger_role_icon2.png" />
      </div>
      <h1 v-if="$route.params.role === 'controller'" class="text-center text-2xl font-black text-primary-blue sm:text-4xl lg:leading-[4.5rem]">Controller Access</h1>
      <h1 v-if="$route.params.role === 'monitor'" class="text-center text-2xl font-black text-primary-blue sm:text-4xl lg:leading-[4.5rem]">Monitor Access</h1>
      <h1 v-if="$route.params.role === 'passenger'" class="text-center text-2xl font-black text-primary-blue sm:text-4xl lg:leading-[4.5rem]">Passenger Access</h1>
    </div>

    <div class="">
      <div class="flex flex-col gap-8 sm:flex-row">
        <div class="flex-grow p-8">
          <form class="flex flex-col" @submit.prevent="login">
            <div v-if="$route.params.role === 'controller' || $route.params.role === 'monitor'">
              <div class="mb-4 rounded pt-3">
                <input ref="authcode" v-model.trim="user_auth_code" placeholder="Code" required="required" class="border-b-1 w-full rounded border-gray-300 bg-white px-4 py-4 text-gray-700 transition duration-300 focus:border-gray-300 focus:outline-none" type="password" />
              </div>
            </div>

            <div v-if="$route.params.role === 'passenger'">
              <div class="mb-4 rounded pt-3">
                <div class="border p-4 rounded shadow text-gray-400">{{username}}</div>
                <div class="mt-4 border p-4 rounded shadow text-sm text-gray-400">********</div>
              </div>
            </div>

            <div class="mb-4 flex justify-between pt-3">
              <button name="button" type="submit" class="relative flex flex-grow items-center justify-center rounded-full bg-primary-blue font-bold text-white shadow-lg outline-none transition duration-200 hover:shadow-xl">
                <span class="py-4">{{ login_loading ? `Login ...` : `Login` }}</span>
                <loading-icon v-if="login_loading" class="absolute right-4" fill_color="fill-blue-500" />
              </button>
            </div>
            <button type="button" class="mt-4 text-center text-sm text-gray-400 outline-none" @click="problems_login_tip = true">Problems logging-in?</button>

            <modal-box v-if="problems_login_tip" title="Login Tips" @modal_closed="problems_login_tip = false"> Please contact the administrator to obtain the login authorization code </modal-box>
          </form>
        </div>
      </div>
    </div>
    <app-bottom-logo class="mt-8 sm:mt-64" />
  </div>
</template>

<script>
import { AppAuthStore } from "@/stores/app_auth_store";

export default {
  setup() {
    const store = AppAuthStore();

    return { store };
  },

  data() {
    return {
      username: "",
      user_password: "",
      user_auth_code: "",
      problems_login_tip: false,
      login_loading: false,
    };
  },

  created() {
    this.role = this.$route.params.role;
    if (this.$route.params.role === "passenger") {
      this.username = "passenger1";
      this.user_password = "12345678";
    } else {
      this.$api.call(this.store.getAppUser()).then((response) => {
        if (response !== null) {
          this.user_auth_code = response.user.auth_code;
          setTimeout(() => {
            this.login();
          }, 500);
        }
      });
    }

    // console.log("Stored user", this.store.app_user);
  },

  mounted() {
    setTimeout(() => {
      this.$refs.authcode.focus();
    }, 1000);
  },

  methods: {
    login() {
      console.log("Handle app login", this.$route.params.role, this.user_auth_code);
      this.login_loading = true;
      setTimeout(() => {
        this.$api.call(this.store.login(this.$route.params.role, this.user_auth_code, this.username, this.user_password)).then((response) => {
          this.login_loading = false;
          if (response.status === "ok") {
            this.$router.push({ name: "devices_path", replace: true });
          } else {
            alert(response.message);
          }
        });
      }, 300);
    },
  },
};
</script>
