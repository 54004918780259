<template>
  <div class="container rounded-lg bg-white p-4 shadow">
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <slot name="icon"></slot>
        <div class="ml-4 font-medium text-primary-blue">{{ title }}</div>
      </div>

      <div v-if="show_right_arrow">
        <svg @click="onclickRight" width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 13L7 7L1 1" stroke="#0072BB" stroke-width="1.5" stroke-linecap="round" />
        </svg>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    onclickRight: {
      type: Function,
      default: null,
    },
    show_right_arrow: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
