<template>
  <div class="flex items-end space-x-2">
    <div class="relative flex flex-col items-center" v-for="n in 10" :key="n">
      <div v-if="n <= Math.ceil(capacity / 10)" class="h-4 w-4 rounded-full bg-primary-blue"></div>
      <div v-else class="h-4 w-4 rounded-full bg-blue-200"></div>
      <div v-if="n <= Math.ceil(capacity / 10)" class="h-[16px] w-[16px] rounded-t-full bg-primary-blue" style="margin-top: 2px"></div>
      <div v-else class="h-[16px] w-[16px] rounded-t-full bg-blue-200" style="margin-top: 2px"></div>
      <div v-if="n == Math.ceil(capacity / 10) && capacity != n * 10" class="absolute left-2 h-full w-1/2 bg-white opacity-75"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    capacity: {
      type: Number,
      default: 0,
    },
  },
};
</script>
