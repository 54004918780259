import { defineStore } from "pinia";

import { consumer } from "@/channels/consumer";
import authHeader from "@/libs/auth_header";

export const AppAuthStore = defineStore("app_auth", {
  state: () => {
    return {
      app_user: null,
      user: {},
    };
  },

  actions: {
    async getAppUser() {
      try {
        this.app_user = JSON.parse(localStorage.getItem("app_user"));
      } catch (e) {
        console.warn("Failed to load app user from locale store");
        this.app_user = null;
      }
      return this.app_user;
    },

    async login(user_role, auth_code, username, password) {
      return this.axios.post("/authenticate", { user: { user_role: user_role, auth_code: auth_code, username: username, password: password } }).then((response) => {
        console.log("Handle auth response", response);
        if (response.data.status === "ok") {
          // TODO: Set expire to localStorage
          localStorage.setItem("app_user", JSON.stringify(response.data));
          this.app_user = response.data.user;
        }
        return response.data;
      });
    },

    async logout() {
      return this.axios.post("/logout", {}, { headers: authHeader() }).then((response) => {
        console.log(response.data);
        localStorage.removeItem("app_user");
        consumer.disconnect();
        return response;
      });
    },

    async user_profile() {
      return this.axios.get("/account/user_profile", { headers: authHeader() }).then((response) => {
        console.log(response.data);
        this.user = response.data.user;
      });
    },
  },
});
