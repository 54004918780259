<template>
  <div class="my-2 flex items-center justify-center" style="height: 4px; background-color: #d2e9e9">
    <div ref="leftbar" style="height: 4px; border-radius: 2px 0 0 2px; position: relative; overflow: hidden; width: 100%">
      <div style="background-color: #d2e9e9; height: 4px; position: absolute; right: 0"></div>
    </div>
    <div style="width: 4px; height: 10px; background-color: #555555; border-radius: 2px"></div>
    <div ref="rightbar" style="height: 4px; border-radius: 0 2px 2px 0; position: relative; overflow: hidden; width: 100%">
      <div style="background-color: #d2e9e9; height: 4px; position: absolute; left: 0"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    direction: {
      type: Number,
      required: 0, // -1: left, 0: center, 1: right, 2: both
    },
    levelValue: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    doUpdate(directionNew, levelValueNew) {
      const leftbar = this.$refs.leftbar;
      const rightbar = this.$refs.rightbar;
      const width = leftbar.offsetWidth;
      const levelWidth = width * (levelValueNew / 100);

      if (directionNew == 0) {
        leftbar.firstElementChild.style.width = "0px";
        rightbar.firstElementChild.style.width = "0px";
      } else {
        if (directionNew === 2) {
          leftbar.lastElementChild.style.backgroundColor = "#3EA400"; // green
          rightbar.lastElementChild.style.backgroundColor = "#3EA400"; // green
        } else {
          if (directionNew < 0) {
            leftbar.lastElementChild.style.backgroundColor = "#3EA400"; // green
            rightbar.lastElementChild.style.backgroundColor = "#d2e9e9";
          } else {
            leftbar.lastElementChild.style.backgroundColor = "#d2e9e9";
            rightbar.lastElementChild.style.backgroundColor = "#3EA400"; // red
          }
        }
        leftbar.firstElementChild.style.width = `${levelWidth}px`;
        rightbar.firstElementChild.style.width = `${levelWidth}px`;
      }
    },
  },

  mounted() {
    this.doUpdate(this.direction, this.levelValue);
  },

  watch: {
    levelValue(newVal) {
      this.doUpdate(this.direction, newVal);
    },

    direction(newDirection) {
      this.doUpdate(newDirection, this.levelValue);
    },
  },
};
</script>
