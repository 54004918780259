<template>
  <div class="container mx-auto max-w-5xl bg-gradient-to-b from-[#37BBED] to-[#0072BB] px-4 py-4 sm:p-8">
    <pannel title="STATUS" class="page_stretch_height">
      <template #icon>
        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15 6.3V17.0937C15.0007 17.2119 14.98 17.3291 14.9388 17.4386C14.8976 17.5481 14.8369 17.6478 14.76 17.7319C14.6832 17.8161 14.5918 17.8831 14.4909 17.9291C14.3901 17.9751 14.2819 17.9992 14.1725 18H0.827499C0.608177 18 0.397825 17.906 0.242663 17.7386C0.0875011 17.5712 0.000220868 17.3441 0 17.1072V0.8928C0 0.4095 0.372499 0 0.831665 0H9.16665V5.4C9.16665 5.63869 9.25445 5.86761 9.41073 6.0364C9.56701 6.20518 9.77897 6.3 9.99999 6.3H15ZM15 4.5H10.8333V0.00270002L15 4.5Z"
            fill="#0072BB" />
        </svg>
      </template>

      <div v-if="store.device.is_online && store.device.boot_status === 1 && store.device.fault_code.length === 0" class="mt-8 flex flex-col items-center">
        <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20.8333 41.6667C9.32708 41.6667 0 32.3396 0 20.8333C0 9.32708 9.32708 0 20.8333 0C32.3396 0 41.6667 9.32708 41.6667 20.8333C41.6667 32.3396 32.3396 41.6667 20.8333 41.6667ZM17.7563 30.1667L32.4854 15.4354L29.5396 12.4896L17.7563 24.275L11.8625 18.3813L8.91667 21.3271L17.7563 30.1667Z"
            fill="#3EA400" />
        </svg>
        <div class="mt-2 text-sm font-bold text-green-600">No Fault</div>
      </div>

      <div v-if="store.device.fault_code.length > 0" class="mt-4 flex items-center">
        <svg class="h-5 w-5" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="8" cy="8" r="8" fill="#DA8076" />
          <circle cx="8.00002" cy="12.2476" r="1.08571" fill="white" />
          <rect x="6.91431" y="3.19995" width="2.17143" height="6.51429" rx="1.08571" fill="white" />
        </svg>
        <span class="ml-2 font-bold text-[#DA8076]">Faults Detected!</span>
      </div>

      <ul class="mt-4">
        <li v-for="error_code in store.device.fault_code" class="mb-4 font-semibold leading-7 text-[#DA8076]">
          <span class="mr-1 rounded bg-[#DA8076]/[.8] px-1 text-sm text-white">{{ error_code }}</span> {{ error_code_des(error_code) }}
        </li>
      </ul>
    </pannel>
  </div>
</template>

<script>
import { DeviceStore } from "@/stores/device_store";
import { SystemStore } from "@/stores/system_store";
import { createUserDevicesChannel } from "@/channels/user_devices_channel.js";
import { fault_code_map } from "@/fault_code.js";

export default {
  setup() {
    const store = DeviceStore();
    const system_store = SystemStore();
    return { store, system_store, user_devices_channel: null, hvac_status: "off" };
  },

  created() {
    this.$api.call(this.store.show(this.$route.params.device_serial)).then(() => {
      this.system_store.set_title(this.store.device.trainno + " | Status");
    });
  },

  mounted() {
    this.user_devices_channel = createUserDevicesChannel();
  },

  methods: {
    error_code_des: function (code) {
      return fault_code_map[code];
    },
  },
};
</script>
