export default function authHeader() {
  let app_user = JSON.parse(localStorage.getItem("app_user"));
  // console.log("Load storage data:", app_user);

  if (app_user && app_user.token) {
    return { Authorization: "Bearer " + app_user.token };
  } else {
    return {};
  }
}
