<template>
  <section class="top-nav" :class="navbar">
    <button v-if="backBtnVisible" @click="goBack" class="flex items-stretch px-4 outline-none">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-10 w-10">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
      </svg>
    </button>

    <button v-else class="invisible flex items-stretch px-4 outline-none">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-10 w-10">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
      </svg>
    </button>

    <div class="text-base">{{ store.app_title }}</div>
    <button class="invisible flex items-stretch px-4 outline-none">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-10 w-10">
        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
      </svg>
    </button>
  </section>
</template>
<script>
import { SystemStore } from "@/stores/system_store";

export default {
  setup() {
    const store = SystemStore();

    return { store };
  },

  data() {
    return {
      backBtnVisible: false,
    };
  },
  computed: {
    navbar() {
      return { "top-nav-show": this.store.app_title !== "" };
    },
  },
  methods: {
    goBack(event) {
      this.$router.go(-1);
    },
  },

  watch: {
    $route(to, from) {
      //console.log("route to name:", to);
      if (to.name === "root_path") {
      } else if (to.name === "app_login_path") {
      } else if (to.name === "devices_path") {
        this.backBtnVisible = false;
      } else if (to.name === "device_path" || to.name === "climate_settings_path") {
      } else if (to.name === "user_profile_path") {
        this.backBtnVisible = false;
      }

      if (to.path !== "/" && to.path !== "/account/devices" && to.path !== "/account/user_profile") {
        this.backBtnVisible = true;
      } else {
        this.backBtnVisible = false;
      }
    },
  },
};
</script>
