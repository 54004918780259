<template>
  <div class="container mx-auto max-w-5xl bg-gradient-to-b from-[#37BBED] to-[#0072BB] pb-4" ref="page_container">
    <img src="@/assets/images/train_model.png" class="w-full" />

    <div class="mt-4 px-4" v-if="store.user.role === 'controller' || store.user.role === 'monitor'">
      <SpeedPowerPannel
        :odom="store.device === null ? 0 : store.device.odom"
        :power_used="store.device === null ? 0 : store.device.power_used"
        :driving_range="store.device === null ? 0 : store.device.driving_range"
        :drive_brake_level="store.device === null ? 0 : store.device.drive_brake_level"
        :drive_applied="store.device === null ? 0 : store.device.drive_applied"
        :brake_applied="store.device === null ? 0 : store.device.brake_applied"
        :speed="store.device === null ? 0 : store.device.train_speed" />
    </div>

    <div class="mt-4 px-4" v-if="store.user.role === 'controller' || store.user.role === 'monitor'">
      <div class="flex justify-between font-bold text-white">
        <div class="w-1/2 text-center">Carriage 1</div>
        <div class="w-1/2 text-center">Carriage 2</div>
      </div>
      <DoorsPannel :train_number="store.device.trainno" :door_1_1_state="store.device.door_1_1_state" :door_1_2_state="store.device.door_1_2_state" :door_2_1_state="store.device.door_2_1_state" :door_2_2_state="store.device.door_2_2_state" />
    </div>

    <div class="mt-4 px-4" v-if="store.user.role === 'controller' || store.user.role === 'monitor'">
      <PowerPannel
        :show_right_arrow="status_available"
        :onclickRight="handlePowerPannelClick"
        :md1_soc="store.device.md1_soc"
        :md2_soc="store.device.md2_soc"
        :md1_power_battery_v="store.device.md1_power_battery_v"
        :md2_power_battery_v="store.device.md2_power_battery_v"
        :md1_bms_charging_status="store.device.md1_bms_charging_status"
        :md2_bms_charging_status="store.device.md2_bms_charging_status"
        :md1_high_voltage="store.device.md1_high_voltage"
        :md2_high_voltage="store.device.md2_high_voltage"
        :md1_380_voltage="store.device.md1_380_voltage"
        :md2_380_voltage="store.device.md2_380_voltage"
        :md1_380_ouput_v="store.device.md1_380_output_v"
        :md2_380_ouput_v="store.device.md2_380_output_v" />
    </div>

    <div class="mt-4 px-4">
      <RoutePannel :stations="store.device.stations" :start_station_id="store.device.start_station_id" :end_station_id="store.device.end_station_id" :current_station_id="store.device.current_station_id" :next_station_id="store.device.next_station_id" />
    </div>

    <div class="mt-4 px-4">
      <ClimatePannel
        v-if="store.user.role === 'controller' || store.user.role === 'monitor'"
        :show_right_arrow="status_available"
        :onclickRight="handleClimatePannelClick"
        :temperature_1="store.device.md1_temp"
        :temperature_2="store.device.md2_temp"
        :hvac_on_1="store.device.md1_hvac_on"
        :hvac_on_2="store.device.md2_hvac_on"
        :md1_heating_on="store.device.md1_auto_heating_on === 1 || store.device.md1_manu_heating_on === 1"
        :md2_heating_on="store.device.md2_auto_heating_on === 1 || store.device.md2_manu_heating_on === 1"
        :md1_cooling_on="store.device.md1_auto_cooling_on === 1 || store.device.md1_manu_cooling_on === 1"
        :md2_cooling_on="store.device.md2_auto_cooling_on === 1 || store.device.md2_manu_cooling_on === 1" />
    </div>

    <div class="mt-4 px-4">
      <PassengerStatPannel :passenger_count="store.device.passenger_count" />
    </div>

    <div class="mt-4 px-4" v-if="store.user.role === 'controller' || store.user.role === 'monitor'">
      <StatusWidget :show_right_arrow="true" :onclickRight="handleStatusPannelClick" :fault_code="store.device.fault_code" />
    </div>

    <div class="mt-4 px-4" v-if="store.user.role === 'passenger'">
      <SpeedPannel :speed="store.device.train_speed" />
    </div>

    <StartButton v-if="store.user.role === 'controller'" :onclickStart="request_bootup" />
  </div>
</template>

<script>
import { computed } from "vue";
import { DeviceStore } from "@/stores/device_store";
import { SystemStore } from "@/stores/system_store";
import { createUserDevicesChannel } from "@/channels/user_devices_channel.js";
import { isCableDebugEnabled } from "@/config/settings.js";

export default {
  setup() {
    const store = DeviceStore();
    const system_store = SystemStore();
    return { store, system_store, user_devices_channel: null, hvac_status: "off" };
  },
  provide() {
    return {
      device_online: computed(() => this.store.device.is_online),
      device_boot_status: computed(() => this.store.device.boot_status),
    };
  },
  created() {
    // Check store device is null
    if (!this.store.device) {
      return;
    }
    setTimeout(() => {
      this.$api.call(this.store.show(this.$route.params.device_serial)).then(() => {
        if (!this.store.device) {
          return;
        }
        this.system_store.set_title(this.store.device.trainno + " | Overview");
      });
    }, 500);
  },
  mounted() {
    console.log("Device dashboard page mounted");

    this.user_devices_channel = createUserDevicesChannel();

    this.user_devices_channel.on("online_status_changed", this.on_device_online_changed);
    this.user_devices_channel.on("device_heartbeat", this.on_deviceHeartbeat);
    this.user_devices_channel.on("boot_status_changed", this.on_bootStatusChanged);
    this.user_devices_channel.on("properties_changed", this.on_properties_changed);
    // Check page_container is not null
    if (!this.$refs.page_container) {
      return;
    }
    setTimeout(() => {
      // check page_container is not null
      if (!this.$refs.page_container) {
        return;
      }
      this.$refs.page_container.scrollTo(0, 0);
    }, 500);
  },

  unmounted() {
    console.log("Device detail page unmounted");
    this.user_devices_channel.cancleOn("online_status_changed", this.on_device_online_changed);
    this.user_devices_channel.cancleOn("device_heartbeat", this.on_deviceHeartbeat);
    this.user_devices_channel.cancleOn("boot_status_changed", this.on_bootStatusChanged);
    this.user_devices_channel.cancleOn("properties_changed", this.on_properties_changed);
    this.user_devices_channel.unsubscribeChannel();
  },
  computed: {
    soc() {
      let s_value = ((this.store.device.md1_soc + this.store.device.md2_soc) / 2 / 10).toFixed(0);
      s_value = parseInt(s_value);
      return s_value;
    },

    status_available() {
      return this.store.device.is_online && this.store.device.boot_status === 1;
    },
  },

  methods: {
    on_device_online_changed(data) {
      if (isCableDebugEnabled()) {
        console.log("When onlineChanged: ", data);
      }
      this.store.sync_current_device(data);
    },

    on_deviceHeartbeat(data) {
      if (data["device_serial"] === this.$route.params.device_serial) {
        if (isCableDebugEnabled()) {
          console.log("When heartbeat: ", data);
        }
        this.store.sync_current_device(data);
      }
    },

    on_bootStatusChanged(data) {
      if (data["device_serial"] === this.$route.params.device_serial) {
        if (isCableDebugEnabled()) {
          console.log("When bootStatusChanged: ", data);
        }
        this.store.update_boot_status(data.boot_status);
      }
    },

    on_properties_changed(data) {
      if (data["device_serial"] === this.$route.params.device_serial) {
        if (isCableDebugEnabled()) {
          console.log("When properties changed: ", data);
        }

        this.store.sync_current_device(data);
      }
    },

    request_bootup() {
      this.user_devices_channel.request_bootup({ device: this.$route.params.device_serial });
    },

    handlePowerPannelClick() {
      this.$router.push({ name: "power_detail_path" });
    },

    handleStatusPannelClick() {
      this.$router.push({ name: "status_report_path" });
    },

    handleClimatePannelClick() {
      this.$router.push({ name: "climate_settings_path" });
    },
  },
};
</script>
