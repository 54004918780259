<template>
  <pannel title="ROUTE" :onclickRight="onclickRight">
    <template #icon>
      <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.36958 9.98611C6.23527 9.98611 5.31555 9.97963 4.39364 9.98826C3.0929 10.0012 2.0834 10.9534 2.08121 12.1626C2.07902 13.3177 3.10385 14.2959 4.37831 14.3153C5.73599 14.3347 7.09586 14.311 8.45354 14.3283C8.72507 14.3326 8.82142 14.2009 8.94405 14.0044C9.5572 13.0047 10.7309 12.5318 11.8455 12.8168C13.0171 13.1169 13.8448 14.0972 13.8821 15.2286C13.9215 16.4378 13.2098 17.4699 12.0689 17.8564C10.9039 18.2515 9.64479 17.8154 8.98785 16.7444C8.80609 16.4486 8.6112 16.3665 8.28054 16.3687C6.85936 16.3795 5.43818 16.3989 4.01919 16.3449C1.86223 16.2629 0.116957 14.5118 0.00527755 12.3915C-0.104213 10.3013 1.5031 8.35807 3.60093 8.03635C4.5951 7.88305 5.59584 7.97805 6.5922 7.96078C7.62141 7.9435 8.65281 7.9651 9.68421 7.9543C10.974 7.94134 11.9638 6.99562 11.9507 5.80158C11.9375 4.63777 10.9368 3.70068 9.67983 3.69204C8.44697 3.68341 7.21192 3.6942 5.97906 3.68341C5.72723 3.68125 5.57176 3.73307 5.42723 3.97706C4.79438 5.03075 3.57466 5.49281 2.40311 5.1603C1.27098 4.84074 0.491414 3.81943 0.487034 2.65563C0.482655 1.46375 1.26441 0.425175 2.4053 0.105614C3.58341 -0.224744 4.78343 0.233006 5.42942 1.29533C5.563 1.51341 5.69438 1.58251 5.93964 1.58035C7.15717 1.56955 8.3747 1.57171 9.59223 1.57603C11.8652 1.58466 13.7923 3.20622 13.9806 5.26394C14.1886 7.55917 12.7061 9.51756 10.3849 9.91917C9.32289 10.1049 8.23456 9.95156 7.36958 9.98611Z"
          fill="#0072BB" />
      </svg>
    </template>

    <div class="mb-4 mt-4 grid grid-cols-2">
      <div class="mb-2" :class="{ 'text-second-black': status_not_available || start_station_id === 0 }">
        From <span class="font-bold">{{ start_station }} </span>
      </div>
      <div class="mr-4 justify-self-end text-second-black">Arrival Time</div>
      <div class="mb-2" :class="{ 'text-second-black': status_not_available || end_station_id === 0 }">
        To: <span class="font-bold">{{ end_station }} </span>
      </div>

      <div class="mb-2 mr-4 justify-self-end font-bold text-second-black">-/-</div>
    </div>

    <input type="range" disabled min="0" :max="stations.length - 1" :value="passed_stops - 1" step="1" class="w-full" :style="{ 'background-size': range_progress }" />

    <div class="mt-4 flex items-center justify-between">
      <div class="grow basis-1/3 text-center" :class="{ 'text-second-black': status_not_available || current_station_id === 0 }">
        Current Station<br />
        <span class="font-bold">{{ current_station }}</span>
      </div>
      <div class="ml-2 mr-2 shrink">
        <svg width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 20L17 10.5L8 1" stroke="#555555" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" />
          <path d="M1 20L10 10.5L1 1" stroke="#555555" stroke-opacity="0.4" stroke-width="1.5" stroke-linecap="round" />
        </svg>
      </div>
      <div class="grow basis-1/3 text-center" :class="{ 'text-second-black': status_not_available || next_station_id === 0 }">
        Next Station<br />
        <span class="font-bold">{{ next_station }}</span>
      </div>
    </div>

    <div class="mt-4 flex items-center justify-between">
      <div class="grow basis-1/3 text-center text-sm" :class="{ 'text-second-black': status_not_available || passed_stops <= 0 }">
        Stops
        <br />
        <div class="font-bold">
          <span>{{ passed_stops > 0 ? passed_stops : "-" }}</span
          >/<span>{{ stations.length }}</span>
        </div>
      </div>
      <div class="ml-2 mr-2 shrink"><div style="border-left: 1px solid rgb(217, 217, 217); height: 32px"></div></div>
      <div class="grow basis-1/3 text-center text-second-black">Arriving in<br /><span class="font-bold">-/-</span></div>
    </div>
  </pannel>
</template>

<script>
export default {
  inject: ["device_online", "device_boot_status"],
  props: {
    onclickRight: {
      type: Function,
      default: null,
    },

    stations: {
      type: Array,
      default: [],
    },

    start_station_id: {
      type: Number,
      default: 0,
    },

    end_station_id: {
      type: Number,
      default: 0,
    },

    current_station_id: {
      type: Number,
      default: 0,
    },

    next_station_id: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {};
  },

  computed: {
    status_not_available() {
      return !this.device_online || this.device_boot_status === 0;
    },
    start_station() {
      if (!this.device_online || this.device_boot_status !== 1) {
        return "-/-";
      }
      const station = this.stations.find((obj) => obj.id === this.start_station_id);
      if (typeof station === "undefined") {
        return "-/-";
      }
      return station.name;
    },

    end_station() {
      if (!this.device_online || this.device_boot_status !== 1) {
        return "-/-";
      }
      const station = this.stations.find((obj) => obj.id === this.end_station_id);
      if (typeof station === "undefined") {
        return "-/-";
      }
      return station.name;
    },

    current_station() {
      if (!this.device_online || this.device_boot_status !== 1) {
        return "-/-";
      }
      const station = this.stations.find((obj) => obj.id === this.current_station_id);
      if (typeof station === "undefined") {
        return "-/-";
      }
      return station.name;
    },

    next_station() {
      if (!this.device_online || this.device_boot_status !== 1) {
        return "-/-";
      }
      const station = this.stations.find((obj) => obj.id === this.next_station_id);
      if (typeof station === "undefined") {
        return "-/-";
      }
      return station.name;
    },

    passed_stops() {
      const station_i = this.stations.findIndex((obj) => obj.id === this.current_station_id);
      if (this.status_not_available || station_i === -1) {
        return -1;
      }
      return station_i + 1;
    },

    range_progress() {
      const station_i = this.stations.findIndex((obj) => obj.id === this.current_station_id);
      if (this.status_not_available || station_i === -1) {
        return "0%";
      }
      let p = (station_i / (this.stations.length - 1)) * 100;
      return `${p}%`;
    },
  },

  mounted() {
    //console.log("stations", this.stations)
  },
};
</script>
