<template>
  <div class="container mx-auto max-w-5xl bg-gradient-to-b from-[#37BBED] to-[#0072BB] px-4 py-4 sm:p-8">
    <pannel title="CLIMATE" class="page_stretch_height">
      <template #icon>
        <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1.84472 2.56132V9.83929C1.0927 10.3846 0.52742 11.1656 0.231157 12.0686C-0.0651047 12.9716 -0.0768866 13.9495 0.197527 14.86C0.47194 15.7704 1.01823 16.5659 1.75687 17.1307C2.49552 17.6955 3.38798 18 4.30435 18C5.22072 18 6.11318 17.6955 6.85182 17.1307C7.59047 16.5659 8.13676 15.7704 8.41117 14.86C8.68558 13.9495 8.6738 12.9716 8.37754 12.0686C8.08128 11.1656 7.516 10.3846 6.76398 9.83929V2.56132C6.76398 1.88201 6.50484 1.23053 6.04357 0.750192C5.5823 0.269852 4.95668 0 4.30435 0C3.65201 0 3.0264 0.269852 2.56513 0.750192C2.10386 1.23053 1.84472 1.88201 1.84472 2.56132ZM2.56513 15.3291C2.10386 14.8488 1.84472 14.1973 1.84472 13.518H6.76398C6.76398 14.1973 6.50484 14.8488 6.04357 15.3291C5.5823 15.8094 4.95668 16.0793 4.30435 16.0793C3.65201 16.0793 3.0264 15.8094 2.56513 15.3291ZM8.47827 3C7.92599 3 7.47827 3.44772 7.47827 4C7.47827 4.55228 7.92599 5 8.47827 5H10.4783C11.0306 5 11.4783 4.55228 11.4783 4C11.4783 3.44772 11.0306 3 10.4783 3H8.47827ZM8.47827 5.5C7.92599 5.5 7.47827 5.94772 7.47827 6.5C7.47827 7.05228 7.92599 7.5 8.47827 7.5H10.4783C11.0306 7.5 11.4783 7.05228 11.4783 6.5C11.4783 5.94772 11.0306 5.5 10.4783 5.5H8.47827ZM8.47827 8C7.92599 8 7.47827 8.44772 7.47827 9C7.47827 9.55228 7.92599 10 8.47827 10H10.4783C11.0306 10 11.4783 9.55228 11.4783 9C11.4783 8.44772 11.0306 8 10.4783 8H8.47827Z"
            fill="#0072BB" />
        </svg>
      </template>

      <ClimateSettingPannel
        cabin_number="MD1"
        :temperature="store.device.md1_temp"
        :ac_stop="store.device.md1_ac_stop"
        :hvac_on="store.device.md1_hvac_on"
        :heating_on="md1_heating_on"
        :cooling_on="md1_cooling_on"
        :onStopPressed="handleStopRequest"
        :onHvacToggle="handleHvacToggle"
        :onHeatingToggle="handleHeatingToggle"
        :onCoolingToggle="handleCoolingToggle">
      </ClimateSettingPannel>
      <ClimateSettingPannel
        cabin_number="MD2"
        :temperature="store.device.md2_temp"
        :ac_stop="store.device.md2_ac_stop"
        :hvac_on="store.device.md2_hvac_on"
        :heating_on="md2_heating_on"
        :cooling_on="md2_cooling_on"
        :onStopPressed="handleStopRequest"
        :onHvacToggle="handleHvacToggle"
        :onHeatingToggle="handleHeatingToggle"
        :onCoolingToggle="handleCoolingToggle">
      </ClimateSettingPannel>
    </pannel>
  </div>
</template>

<script>
import { computed } from "vue";
import { DeviceStore } from "@/stores/device_store";
import { SystemStore } from "@/stores/system_store";
import { createUserDevicesChannel } from "@/channels/user_devices_channel.js";
import { isCableDebugEnabled } from "@/config/settings.js";

export default {
  setup() {
    const store = DeviceStore();
    const system_store = SystemStore();
    return { store, system_store, user_devices_channel: null, hvac_status: "off" };
  },
  provide() {
    return {
      device_online: computed(() => this.store.device.is_online),
      device_boot_status: computed(() => this.store.device.boot_status),
    };
  },

  computed: {
    md1_heating_on() {
      return this.store.device.md1_auto_heating_on === 1 || this.store.device.md1_manu_heating_on === 1;
    },
    md2_heating_on() {
      return this.store.device.md2_auto_heating_on === 1 || this.store.device.md2_manu_heating_on === 1;
    },

    md1_cooling_on() {
      return this.store.device.md1_auto_cooling_on === 1 || this.store.device.md1_manu_cooling_on === 1;
    },

    md2_cooling_on() {
      return this.store.device.md2_auto_cooling_on === 1 || this.store.device.md2_manu_cooling_on === 1;
    },
  },

  created() {
    this.$api.call(this.store.show(this.$route.params.device_serial)).then(() => {
      this.system_store.set_title(this.store.device.trainno + " | Climate");
    });
  },

  methods: {
    handleStopRequest(cabin_number) {
      console.log("Handle stop request for cabin ", cabin_number);
      this.user_devices_channel.request_ac_stop({ device: this.$route.params.device_serial, cabin: cabin_number, enable: true });
    },

    handleHvacToggle(cabin_number) {
      console.log("Handle hvac toggle", cabin_number);
      this.user_devices_channel.request_hvac_toggle({ device: this.$route.params.device_serial, cabin: cabin_number, enable: true });
    },

    handleHeatingToggle(cabin_number) {
      console.log("Handle heating toggle", cabin_number);
      this.user_devices_channel.request_heating_toggle({ device: this.$route.params.device_serial, cabin: cabin_number, enable: true });
    },

    handleCoolingToggle(cabin_number) {
      console.log("Handle cooling toggle", cabin_number);
      this.user_devices_channel.request_cooling_toggle({ device: this.$route.params.device_serial, cabin: cabin_number, enable: true });
    },

    on_device_online_changed(data) {
      console.log("==== on_device_online_changed ....", data);
      this.store.sync_current_device(data);
    },

    on_deviceHeartbeat(data) {
      if (data["device_serial"] === this.$route.params.device_serial) {
        if (isCableDebugEnabled()) {
          console.log("When heartbeat: ", data);
        }
        this.store.sync_current_device(data);
      }
    },

    on_bootStatusChanged(data) {
      if (data["device_serial"] === this.$route.params.device_serial) {
        if (isCableDebugEnabled()) {
          console.log("When bootStatusChanged: ", data);
        }
        this.store.update_boot_status(data.boot_status);
      }
    },

    on_properties_changed(data) {
      if (data["device_serial"] === this.$route.params.device_serial) {
        if (isCableDebugEnabled()) {
          console.log("When properties changed: ", data);
        }
        this.store.sync_current_device(data);
      }
    },
  },

  mounted() {
    console.log("Device climate settings page mounted");
    this.user_devices_channel = createUserDevicesChannel();
    this.user_devices_channel.on("online_status_changed", this.on_device_online_changed);
    this.user_devices_channel.on("device_heartbeat", this.on_deviceHeartbeat);
    this.user_devices_channel.on("boot_status_changed", this.on_bootStatusChanged);
    this.user_devices_channel.on("properties_changed", this.on_properties_changed);
  },

  unmounted() {
    console.log("Device detail page unmounted");
    this.user_devices_channel.cancleOn("online_status_changed", this.on_device_online_changed);
    this.user_devices_channel.cancleOn("device_heartbeat", this.on_deviceHeartbeat);
    this.user_devices_channel.cancleOn("boot_status_changed", this.on_bootStatusChanged);
    this.user_devices_channel.cancleOn("properties_changed", this.on_properties_changed);
    this.user_devices_channel.unsubscribeChannel();
  },
};
</script>
