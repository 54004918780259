<template>
  <div class="container mx-auto h-[82vh] max-w-5xl bg-gradient-to-b from-[#37BBED] to-[#0072BB] px-4 py-4 sm:p-8">
    <PowerDetailPannel
      :md1_soc="store.device.md1_soc"
      :md2_soc="store.device.md2_soc"
      :md1_bms_charging_status="store.device.md1_bms_charging_status"
      :md2_bms_charging_status="store.device.md2_bms_charging_status"
      :md1_power_battery_current="store.device.md1_power_battery_current"
      :md2_power_battery_current="store.device.md2_power_battery_current"
      :power_used="store.device.power_used"
      :odom="store.device.odom"
      :renewable_energy="store.device.renewable_energy"
      :drive_brake_level="store.device.drive_brake_level" />
  </div>
</template>

<script>
import { computed } from "vue";
import { DeviceStore } from "@/stores/device_store";
import { SystemStore } from "@/stores/system_store";
import { createUserDevicesChannel } from "@/channels/user_devices_channel.js";
import { isCableDebugEnabled } from "@/config/settings.js";

export default {
  setup() {
    const store = DeviceStore();
    const system_store = SystemStore();
    return { store, system_store, user_devices_channel: null, hvac_status: "off" };
  },
  provide() {
    return {
      device_online: computed(() => this.store.device.is_online),
      device_boot_status: computed(() => this.store.device.boot_status),
    };
  },

  created() {
    this.$api.call(this.store.show(this.$route.params.device_serial)).then(() => {
      this.system_store.set_title(this.store.device.trainno + " | Power");
    });
  },

  mounted() {
    console.log("Device power detail page mounted");
    this.user_devices_channel = createUserDevicesChannel();
    this.user_devices_channel.on("device_heartbeat", this.on_deviceHeartbeat);
    this.user_devices_channel.on("boot_status_changed", this.on_bootStatusChanged);
    this.user_devices_channel.on("properties_changed", this.on_properties_changed);
  },

  unmounted() {
    console.log("Device power detail page unmounted");
    this.user_devices_channel.cancleOn("device_heartbeat", this.on_deviceHeartbeat);
    this.user_devices_channel.cancleOn("boot_status_changed", this.on_bootStatusChanged);
    this.user_devices_channel.cancleOn("properties_changed", this.on_properties_changed);
    this.user_devices_channel.unsubscribeChannel();
  },

  methods: {
    on_deviceHeartbeat(data) {
      if (data["device_serial"] === this.$route.params.device_serial) {
        if (isCableDebugEnabled()) {
          console.log("When heartbeat: ", data);
        }
        this.store.sync_current_device(data);
      }
    },

    on_bootStatusChanged(data) {
      if (data["device_serial"] === this.$route.params.device_serial) {
        if (isCableDebugEnabled()) {
          console.log("When bootStatusChanged: ", data);
        }
        this.store.update_boot_status(data.boot_status);
      }
    },

    on_properties_changed(data) {
      if (data["device_serial"] === this.$route.params.device_serial) {
        if (isCableDebugEnabled()) {
          console.log("When properties changed: ", data);
        }
        this.store.sync_current_device(data);
      }
    },
  },
};
</script>
