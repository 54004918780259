<template>
  <div>
    <div class="container mx-auto max-w-5xl px-4 sm:p-8">
      <div class="flex justify-center text-center"><img src="@/assets/images/app_icon.png" /></div>
      <h1 class="mt-4 text-center text-4xl font-black text-primary-blue sm:text-3xl">{{ $t("home.hero_slogan_html") }}</h1>
      <p class="mt-4 text-center text-2xl text-primary-blue sm:text-xl">{{ store.server_date }}</p>
      <div class="mt-6 text-center text-2xl font-bold text-primary-black sm:text-xl">{{ $t("home.enter_as") }}</div>

      <div class="grid grid-cols-3 justify-items-center">
        <div class="border-1 flex h-24 w-24 items-center justify-center rounded-full shadow-xl sm:h-32 sm:w-32" @click="login('controller')">
          <img src="@/assets/images/controller_role_icon.png" />
        </div>
        <div class="border-1 flex h-24 w-24 items-center justify-center rounded-full shadow-xl sm:h-32 sm:w-32" @click="login('monitor')">
          <img src="@/assets/images/monitor_role_icon.png" />
        </div>
        <div class="border-1 flex h-24 w-24 items-center justify-center rounded-full shadow-xl sm:h-32 sm:w-32" @click="login('passenger')">
          <img src="@/assets/images/passenger_role_icon.png" />
        </div>

        <label class="p-4 text-sm font-medium text-primary-black">{{ $t("home.controller") }}</label>
        <label class="p-4 text-sm font-medium text-primary-black">{{ $t("home.monitor") }}</label>
        <label class="p-4 text-sm font-medium text-primary-black">{{ $t("home.passenger") }}</label>
      </div>
    </div>
    <app-bottom-logo class="mt-20 sm:mt-64" />
  </div>
</template>

<script>
import { AppAuthStore } from "@/stores/app_auth_store";
import { SystemStore } from "@/stores/system_store";
import AppBottomLogo from "@/views/shared/_app_bottom_logo.vue";
export default {
  components: {
    "app-bottom-logo": AppBottomLogo,
  },

  setup() {
    const auth_store = AppAuthStore();
    const store = SystemStore();

    return { auth_store, store };
  },

  created() {
    this.$api.call(this.store.info());

    this.$api.call(this.auth_store.getAppUser()).then((response) => {
      if (response !== null) {
        this.login(response.user.role);
      }
    });
  },

  methods: {
    login(role) {
      this.$router.push({ name: "app_login_path", params: { role: role } });
    },
  },
};
</script>
